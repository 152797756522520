import React, { Fragment } from 'react'
import { Container, makeStyles, TableCell, Typography, TableContainer, TableHead, TableRow, Paper, Table, TableBody } from '@material-ui/core';
import { BackNav } from '../../components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import createHashMap from '../../helpers/create-hash-map';
import { tableCss } from './../../components/Tables/TableCss';
import { CustomTooltip, StepIdea } from '../../components/Shared';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function FluxOfIdeasPage() {
  const { ideasStepsFlux } = useSelector((state) => state.domains);
  const classes = useStyles();
  const { t } = useTranslation();
  const linhasTable = { text: t('linhas_por_pagina') };
  const tableClasses = tableCss(linhasTable);

  const hashMapIdeiasFlux = createHashMap(ideasStepsFlux, '_id')

  const ideasStepFluxSorted = ideasStepsFlux ? ideasStepsFlux.sort((a, b) => {
    if (a.curationOrder > b.curationOrder) return 1;

    if (a.curationOrder < b.curationOrder) return -1;

    return 0;
  }) : []

  function handleView(id) {
    history.push(`/flux-of-ideas-edit/${id}`);
  }

  const tableInfo = {
    columns: [
      { id: 'step_name', label: t('step_name') },
      { id: 'next_step_when_approved', label: t('next_step_when_approved') },
      { id: 'next_step_when_rejected', label: t('next_step_when_rejected') },
      { id: 'next_step_when_pending_review', label: t('next_step_when_pending_review') }
    ],
    data: ideasStepFluxSorted?.map(item => (
      <TableRow hover classes={{ root: tableClasses.tableRow }}>
        <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>
          {/* <CustomTooltip title={item.name} aria-label={item.name} placement="bottom-start">
            <Typography variant="subtitle2" className={tableClasses.link} onClick={() => handleView(item._id)}>{item.name}</Typography>
          </CustomTooltip> */}
          <StepIdea step={item} />
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{hashMapIdeiasFlux[item.nextStepWhenApproved] ? <StepIdea step={hashMapIdeiasFlux[item.nextStepWhenApproved]} /> : '-'}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{hashMapIdeiasFlux[item.nextStepWhenRejected] ? <StepIdea step={hashMapIdeiasFlux[item.nextStepWhenRejected]} /> : '-'}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{hashMapIdeiasFlux[item.nextStepWhenPendingReview] ? <StepIdea step={hashMapIdeiasFlux[item.nextStepWhenPendingReview]} /> : '-'}</TableCell>
      </TableRow>
    )),
  };

  return (
    <Container maxWidth="lg" className={classes.root}>
      <BackNav title={t('fluxos_de_aprovacao_ideias')} pathname="/" />
      {/* <TablePanel tableInfo={tableInfo} /> */}
      <Paper className={tableClasses.paperRoot}>
        <TableContainer>
          <Table stickyHeader classes={{ root: tableClasses.table }}>
            <TableHead>
              <TableRow>
                {tableInfo.columns.map((column) => (
                  <TableCell
                    key={column.id}
                    classes={{ root: tableClasses.tableCellHeader }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableInfo.data.map((item) => (
                item
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
}

export { FluxOfIdeasPage };